// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact__form .form-control {
    padding: 1.375rem .75rem;
    line-height: 1.5;
    color: var(--text-color);
    background-color: var(--bg-color);
    border-radius: 0 !important;
    border: 1px solid var(--secondary-color);
}

.contact__form input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + .75rem + 2px);
}

button.btn.ac_btn:hover {
    color: var(--secondary-color);
}

.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 999999999;
    background: var(--text-color);
    transform: translateX(100%);
    animation: shift-rightwards 1s ease-in-out infinite;
    animation-delay: .3s;
}

@keyframes shift-rightwards {
    0% {
        transform: translateX(-100%);
    }
    40% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/contact/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,wBAAwB;IACxB,iCAAiC;IACjC,2BAA2B;IAC3B,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B,2BAA2B;IAC3B,mDAAmD;IACnD,oBAAoB;AACxB;;AAEA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,yBAAyB;IAC7B;IACA;QACI,yBAAyB;IAC7B;IACA;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".contact__form .form-control {\n    padding: 1.375rem .75rem;\n    line-height: 1.5;\n    color: var(--text-color);\n    background-color: var(--bg-color);\n    border-radius: 0 !important;\n    border: 1px solid var(--secondary-color);\n}\n\n.contact__form input.form-control {\n    margin-bottom: 2em;\n    height: calc(2.5em + .75rem + 2px);\n}\n\nbutton.btn.ac_btn:hover {\n    color: var(--secondary-color);\n}\n\n.loading-bar {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 10px;\n    z-index: 999999999;\n    background: var(--text-color);\n    transform: translateX(100%);\n    animation: shift-rightwards 1s ease-in-out infinite;\n    animation-delay: .3s;\n}\n\n@keyframes shift-rightwards {\n    0% {\n        transform: translateX(-100%);\n    }\n    40% {\n        transform: translateX(0%);\n    }\n    60% {\n        transform: translateX(0%);\n    }\n    100% {\n        transform: translateX(100%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
