// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 991px) {
    .s_c {
        padding-top: 40px;
    }
}

.page-enter {
    transform: translateY(100%);
}

.page-enter-active {
    transform: translateY(0%);
    transition: all 400ms ease-out;
}

.page-exit {
    transform: translateY(0%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.page-exit-active {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-130%);
    transition: all 400ms ease-out;
}

@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1140px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;AACV;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,4BAA4B;IAC5B,8BAA8B;AAClC;;AAEA;IACI;;;;;;QAMI,iBAAiB;IACrB;AACJ","sourcesContent":["@media only screen and (max-width: 991px) {\n    .s_c {\n        padding-top: 40px;\n    }\n}\n\n.page-enter {\n    transform: translateY(100%);\n}\n\n.page-enter-active {\n    transform: translateY(0%);\n    transition: all 400ms ease-out;\n}\n\n.page-exit {\n    transform: translateY(0%);\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n}\n\n.page-exit-active {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    transform: translateY(-130%);\n    transition: all 400ms ease-out;\n}\n\n@media (min-width: 1400px) {\n    .container,\n    .container-lg,\n    .container-md,\n    .container-sm,\n    .container-xl,\n    .container-xxl {\n        max-width: 1140px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
